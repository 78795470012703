/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

/* Global styles */
body {
  font-family: 'Play', sans-serif;
  background-color: #f9f9f9; /* Updated background color */
  margin: 0;
  padding: 0;
  font-weight: 400; /* Ensure default font weight */
}

/* Define utility classes for different font weights */
.play-regular {
  font-family: 'Play', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.play-bold {
  font-family: 'Play', sans-serif;
  font-weight: 700;
  font-style: normal;
}

/* Other styles */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.vh-100 {
  height: 100vh !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.verification-box {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  margin: 0.5rem;
}

/* Updated Navbar background color */
.navbar {
  background-color: #ffffff !important;
}

.overlay-cv2json {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); /* Translucent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-message {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); /* Translucent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

